import React from 'react';

import Specialist from 'images/icons/start/specialist.svg';
import Treatment from 'images/icons/start/treatment.svg';
import PlanTreatment from 'images/icons/start/plan-treatment.svg';
import MedTreatment from 'images/icons/start/med-specialistes.svg';
import Check from 'images/icons/start/check.svg';
import Hebergeur from 'images/icons/start/small-hebergeur.svg';
import Secure from 'images/icons/start/small-icon-secure.svg';
import Livraison from 'images/icons/start/small-livraison-discrete.svg';
import Price from 'images/new-images/icon-small-economies-square.svg';
import config from 'utils/siteConfig';

type ReinsuranceColumnProps = {
  price: string
  socialSecurityMode: boolean
}

const ReinsuranceColumn = ({ price }: ReinsuranceColumnProps) => (
  <div className="text-left d-flex flex-column right-div">
    <div className="consulter-reinsurance-div">
      <div className="health-charles-new-way text-left d-flex flex-column">
        <h2>Charles, la santé repensée&nbsp;: </h2>
        <div className="ml-xl-3 d-inline-flex ">
          <div className="picto-reinsurance-list">
            <Specialist width={20} />
          </div>
          <span> Téléconsultez un spécialiste</span>

        </div>
        <div className="ml-xl-3 d-inline-flex ">
          <div className="picto-reinsurance-list">
            <Price width={20} />
          </div>
          <span> {(price || `${config.consultationPrice}€`)} au lieu de 80€ en moyenne en cabinet</span>

        </div>
        <div className="ml-xl-3 d-inline-flex ">
          <div className="picto-reinsurance-list">
            <PlanTreatment width={20} />
          </div>
          <span> Recevez une prescription pour des examens</span>

        </div>
        <div className="ml-xl-3 d-inline-flex ">
          <div className="picto-reinsurance-list">
            <MedTreatment width={20} />
          </div>
          <span> Bénéficiez d'un suivi par nos équipes</span>
        </div>
      </div>
      <div className="charles-seriousness text-left d-flex flex-column">
        <div className="ml-xl-3 d-inline-flex ">
          <div className="picto-reinsurance-list">
            <Check width={20} />
          </div>
          <span> Médecins spécialistes français</span>
        </div>
        <div className="ml-xl-3 d-inline-flex ">
          <div className="picto-reinsurance-list">
            <Check width={20} />
          </div>
          <span> Accompagnement et suivi</span>
        </div>
        <div className="ml-xl-3 d-inline-flex ">
          <div className="picto-reinsurance-list">
            <Check width={20} />
          </div>
          <span> Analyse des résultats</span>
        </div>
      </div>
      <div className="privacy-respect text-left d-flex flex-column">
        <h2>Charles respecte votre vie privée&nbsp;: </h2>
        <div className="ml-xl-3 d-inline-flex ">
          <div className="picto-reinsurance-list">
            <Secure width={20} />
          </div>
          <span>
            Vos données sont sécurisées et soumises au secret
            médical
          </span>
        </div>
        <div className="ml-xl-3 d-inline-flex ">
          <div className="picto-reinsurance-list">
            <Hebergeur width={20} />
          </div>
          <span>
            Charles utilise un Hébergeur Agréé de Données de Santé
          </span>
        </div>
        <div className="ml-xl-3 d-inline-flex ">
          <div className="picto-reinsurance-list">
            <Livraison width={20} />
          </div>
          <span>
            Livraison discrète chez vous ou en relais colis
          </span>
        </div>
      </div>
    </div>
  </div>
);

export default ReinsuranceColumn;

import React, { PropsWithChildren } from 'react';
import { frontAppUrl } from 'utils/siteConfig';

type OnboardingLinkProps = {
  spe: string
  onboardingParams?: {
    [key: string]: string
  }
}

export const OnboardingLink = (props: PropsWithChildren<OnboardingLinkProps>) => {
  const spe = props.spe || 'erectile_dysfunction';
  const url = new URL(`${frontAppUrl}/onboarding.html`);

  url.searchParams.append("spe", spe);
  url.searchParams.append("refresh", "true");

  if(props.onboardingParams){
    Object.entries(props.onboardingParams).forEach((pair) => {
      const key = pair[0]
      const value = pair[1]
      url.searchParams.append(key, value)
    })
  }

  return (
    <a rel="noopener noreferrer nofollow" href={url.toString()}>
      {props.children}
    </a>
  );
};

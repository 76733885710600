import React, { useState } from "react";
import Image from "components/common/utils/Image";
import "./styles.sass";

const DoctorsTalks = ({ data }) => {

  const docTalks = data.doctorsTalks;

  return (
    <>
    
      <p className="start-text text-center d-flex start-text-general">
        {docTalks.subtitle}
      </p>
      <div className="doctor-message-div d-flex mt-3">
        <div className=" ">
          <Image
            filename={docTalks.doctorPicture}
            alt={docTalks.alt}
            title={docTalks.title}
            className="doctor-round-face p-0 mr-2"
          />
        </div>
        <div className=" ">
          <div className="doctor-talks ml-2 text-left">
            {docTalks.firstParagraph}
          </div>
        </div>
      </div>
      <div className="doctor-message-div d-flex mt-3">
        <div className=" ">
          <Image
            filename={docTalks.doctorPicture}
            alt={docTalks.alt}
            title={docTalks.title}
            className="doctor-round-face p-0 mr-2"
          />
        </div>
        <div className=" ">
          <div className="doctor-talks ml-2 text-left">
            {docTalks.secondParagraph}
          </div>
        </div>
      </div>
    </>
  );
};

export default DoctorsTalks;
